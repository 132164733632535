import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';
import dayjs from 'dayjs';

import { Preferences } from '@capacitor/preferences';
import { zodResolver } from '@hookform/resolvers/zod';
import { IonButton, IonHeader, IonSpinner } from '@ionic/react';

import Input from '@/components/atoms/Input.tsx';
import PhoneOutlineIcon from '@/components/icons/common/PhoneOutlineIcon.tsx';
import OtpInput from '@/components/molecules/OtpInput.tsx';
import { masks } from '@/consts/masks.ts';
import { storageKeys } from '@/consts/storageKeys.ts';
import { IOtpSendRequest, otpSendSchema, useOtpSend } from '@/modules/users/api/useOtpSend.ts';
import { useOtpVerify } from '@/modules/users/api/useOtpVerify.ts';
import { useSignup } from '@/modules/users/api/useSignup.ts';
import { mask } from '@/utils/mask/mask.ts';

export default function UserSignupBox({ onFinish }: { onFinish: () => void }) {
  const { t } = useTranslation();
  const [step, setStep] = useState<'send' | 'verify'>('send');
  const [seconds, setSeconds] = useState(0);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');

  const otpSend = useOtpSend();
  const otpVerify = useOtpVerify();
  const signup = useSignup();

  const otpSendForm = useForm<IOtpSendRequest>({
    defaultValues: { phone: '' },
    resolver: zodResolver(otpSendSchema),
  });

  const onSignup = async () => {
    if (code.length < 4) {
      setCodeError(t('forms.required'));
    } else {
      setCodeError('');
      const token = await Preferences.get({ key: storageKeys.fingerPrint });

      signup
        .mutateAsync({
          code: code,
          fingerPrint: token.value || '',
          name: `Пользователь ${dayjs().format('DD MMMM, HH:mm')}`,
          phone: otpSendForm.getValues().phone?.replace(/\D/g, ''),
        })
        .then(onFinish)
        .catch(() => {
          setCodeError('Неверный код');
        });
    }
  };

  useEffect(() => {
    const interval = setInterval(
      () => (seconds > 0 ? setSeconds(seconds - 1) : clearInterval(interval)),
      1000,
    );

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    if (code.length == 4) {
      onSignup();
    } else {
      setCodeError('');
    }
  }, [code]);

  return (
    <div
      className={clsx('relative z-10 flex max-w-full flex-col gap-4 rounded-xl px-4 py-8 lg:px-8')}
    >
      <div className="flex flex-col gap-4">
        <IonHeader
          className={clsx('p-2 text-center font-Inter text-20 font-5 text-[#040415] lg:text-24')}
        >
          {t('auth.signup')}
        </IonHeader>
        <p className="text-center text-14 text-[#212529] text-opacity-50 lg:text-16">
          {t('auth.signupToContinue')}
        </p>
      </div>

      <form
        className={clsx('flex flex-col gap-6 overflow-visible rounded-20 lg:gap-12')}
        onSubmit={otpSendForm.handleSubmit((values) => {
          if (!seconds) {
            setStep('verify');
            setCode('');
            setCodeError('');
            otpSend.mutateAsync({ phone: values.phone?.replace(/\D/g, '') }).then(() => {
              setSeconds(30);
            });
          }
        })}
      >
        <Controller
          control={otpSendForm.control}
          name="phone"
          render={({ field, fieldState }) => (
            <Input
              errorText={fieldState.error?.message}
              leftIcon={<PhoneOutlineIcon aria-hidden="true" className="text-[#A8AFB9]" />}
              onIonInput={(e) => {
                field.onChange(e.target?.value as string);
              }}
              placeholder={t('auth.phoneInput')}
              type="text"
              value={mask(field.value, masks.phone)}
            />
          )}
        />

        <div className="flex flex-col gap-2">
          {step == 'verify' && (
            <div
              className={clsx(
                'flex w-full',
                otpVerify.isPending ? 'pointer-events-none opacity-50' : '',
              )}
            >
              <OtpInput
                className="mx-auto max-w-80"
                errorText={codeError}
                label={t('auth.codeConfirmation')}
                length={4}
                onChange={setCode}
                onCompleteInput={() => {
                  //
                }}
                value={code}
              />
            </div>
          )}

          <IonButton
            className="h-14 text-16 font-7 lg:text-18 [&::part(native)]:bg-gobazar"
            disabled={otpSend.isPending || signup.isPending || seconds > 0}
            expand="block"
            type="submit"
          >
            <div>
              {signup.isPending || otpVerify.isPending || otpSend.isPending ? (
                <IonSpinner />
              ) : (
                <span className="flex items-center">
                  {t('auth.codeGet')}
                  {!!seconds && (
                    <span className="ml-1 !flex !w-20">
                      {seconds > 0 ? `0:${seconds < 10 ? 0 : ''}${seconds}` : ''}
                    </span>
                  )}
                </span>
              )}
            </div>
          </IonButton>

          <IonButton
            className="text-14 font-5 !text-[#04041560] lg:text-18"
            disabled={signup.isPending}
            expand="block"
            fill="clear"
            onClick={onFinish}
            size="small"
          >
            {t('common.close')}
          </IonButton>
        </div>
      </form>
    </div>
  );
}
